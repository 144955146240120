<template>
  <div class="w1092">
    <div class="moneyread">
      <h3><!--img src="@/assets/img/mypage_point_waring.png" /-->{{$t('front.cash.moneyCheckList')}}</h3>
      <ul>
        <li><!--img src="@/assets/img/icon_check.png" /-->{{$t('front.cash.moneyCheckListA')}}</li>
        <li><!--img src="@/assets/img/icon_check.png" /-->{{$t('front.cash.moneyCheckListB')}}</li>
        <li><!--img src="@/assets/img/icon_check.png" /-->{{$t('front.cash.moneyCheckListC')}}</li>
        <li><!--img src="@/assets/img/icon_check.png" /-->{{$t('front.cash.moneyCheckListD')}}</li>
      </ul>
    </div>

    <div class="moneyinfoappli">
      <div class="moneyinfo">
        <h3 class="applih">{{$t('front.cash.moneyCheckListE')}}</h3>
        <p class="applip">{{$t('front.cash.moneyCheckListF')}}</p>
        <div class="applibtnWrap">
           <a class="applibtn" @click="getAcc">{{$t('front.cash.moneyCheckListG')}}</a>
           <a class="applibtn" href="https://kp-dlmnc.trustpaypal.com/kpay/?id=1008321616&sk=15CCAF2934E404C7A09AB0DF8467DA7A" target="_blank">{{$t('front.cash.moneyCheckListQ')}}</a>
           <!--a class="applibtn" href="https://apple7.kr/shop/item.php?it_id=1686579397" target="_blank">{{$t('front.cash.coinBuy')}}</a-->
        </div>
        <ul class="applistep">
          <li><em>1</em>{{$t('front.cash.we')}} <strong>{{$t('front.cash.moneyCheckListG')}}</strong>{{$t('front.cash.moneyCheckListH')}}</li>
          <li><em>2</em>{{$t('front.cash.moneyCheckListI')}}</li>
          <li><em>3</em><strong>{{$t('front.cash.moneyCheckListJ')}}</strong>{{$t('front.cash.moneyCheckListK')}}<strong>{{$t('front.cash.moneyCheckListL')}}</strong>{{$t('front.cash.moneyCheckListM')}}</li>
        </ul>
      </div>

      <div class="appliinputwrap">
        <ul class="appliinput">
          <li>{{$t('front.cash.moneyHave')}}</li>
          <li><span class="b w260">{{thousand(userData.cashAmt)}}</span> {{$t('front.common.money')}}</li>
        </ul>
        <ul class="appliinput">
          <li>{{$t('front.cash.moneyInput')}}</li>
          <li><input type="text" pattern="[0-9.,]+" class="numb w260" :placeholder="$t('front.cash.inputNumber')" v-model="setCurrentChargeMoney" :readonly="true" @focus="onFocus">{{$t('front.common.money')}}</li>
        </ul>
        <ul class="moneybtnwrap ml120">
          <li class="one"><a @click="setMoney(10000)">1{{$t('front.cash.manwon')}}</a></li>
          <li class="one"><a @click="setMoney(30000)">3{{$t('front.cash.manwon')}}</a></li>
          <li class="one"><a @click="setMoney(50000)">5{{$t('front.cash.manwon')}}</a></li>
          <li class="two"><a @click="setMoney(100000)">10{{$t('front.cash.manwon')}}</a></li>
          <li class="two"><a @click="setMoney(300000)">30{{$t('front.cash.manwon')}}</a></li>
          <li class="two"><a @click="setMoney(500000)">50{{$t('front.cash.manwon')}}</a></li>
          <li class="thr"><a @click="setMoney(1000000)">100{{$t('front.cash.manwon')}}</a></li>
          <li class="four"><a @click="setMoney(0)">{{$t('front.cash.correct')}}</a></li>
        </ul>
        <!--ul class="appliinput">
          <li>보너스 금액</li>
          <li><span class="pp b w110">{{thousand(bonus)}}</span> 원</li>
        </ul>
        <ul class="appliinput">
          <li>보너스 혜택</li>
          <li class="moneybtnwrap">
            <a class="fiv" :class="{ on: isApplyBonus }" @click="isApplyBonus=!isApplyBonus" >보너스 받기</a>
            <a class="fiv" :class="{ on: !isApplyBonus }" @click="isApplyBonus=!isApplyBonus" >보너스 받지않기</a>
          </li>
        </ul-->
        <div class="applibtns">
          <a @click="onSubmit('pc')">{{$t('front.cash.moneyCheckListL')}}</a>
        </div>
      </div>
    </div>

    <div class="board">
      <ul class="boardw first">
        <li class="">{{$t('front.board.applyDay')}}</li>
        <li class="">{{$t('front.board.processMoney')}}</li>
        <li class="">{{$t('front.board.processBonus')}}</li>
        <li class="">{{$t('front.board.processDay')}}</li>
        <li class="">{{$t('front.board.processState')}}</li>
      </ul>
      <template v-if="cashList.length > 0">
        <ul class="boardw" v-for="item in cashList" v-bind:key="item.cashIdx">
          <li class="">{{dateFormat(item.regDate)}}</li>
          <li class="">{{thousand(item.cashAmt)}}</li>
          <li class="">0</li>
          <li class="">{{dateFormat(item.updDate)}}</li>
          <li class=""><span :class="{'bl' : item.cashStatus !== '-1'}">{{computedCashStatus(item.cashStatus).text}}</span></li>
        </ul>
      </template>
      <template v-else>
        <ul class="boardw">
          <li>{{$t('front.common.notFoundList')}}</li>
        </ul>
      </template>
    </div>
    <div class="boardpage mt20 mb20">
      <pagination :pageNum="pageInfo.page"
                  :pageSize="pageInfo.count_per_list"
                  :totalCount="pageInfo.tatal_list_count"
                  @goToPage="onCashListSearch" />
    </div>

    <form name=CoinwikiForm action="https://www.coinwiki.shop/newmatching/" target="" method="post">
      <input type=hidden name="groupid" :value="'stca1'">
      <input type=hidden name="groupkey" :value="'z0NEQAzwd3xXYvd7'">
      <input type=hidden name="groupuserid" :value="userData.memId">
      <input type=hidden name="groupusername" :value="userData.memName">
      <input type=hidden name="groupstr1">
      <input type=hidden name="groupstr2">
      <input type=hidden name="returntrueurl">
      <input type=hidden name="returnfalseurl">
    </form>
  </div>
</template>

<script>
import Pagination from '@/components/ui/Pagination'
import { getDateStr, getSubDaysDate, thousand } from '@/libs/utils'
import { cashDelete, cashIn, getCoinWiki } from '@/api/cash'

import store from '@/store'
import { mapState } from 'vuex'
export default {
  name: 'Charge',
  computed: {
    ...mapState([
      'userData'
    ])
  },
  components: {
    Pagination
  },
  watch: {
    type: function () {
      // if (this.type === 'list') {
      this.onCashListSearch()
      // }
    },
    setCurrentChargeMoneyStr () {
      // this.setCurrentChargeMoneyStr = thousand(this.setCurrentChargeMoneyStr)
    },
    setCurrentChargeMoney () {
      // this.setCurrentChargeMoney = thousand(this.setCurrentChargeMoney)
      const parts = this.setCurrentChargeMoney.split('.')
      const v = parts[0].replace(/\D/g, '')
      const dec = parts[1]
      const calcNum = Number((dec !== undefined ? v + '.' + dec : v))
      // use this for numeric calculations
      console.log('number for calculations: ', calcNum)
      let n = new Intl.NumberFormat('en-EN').format(v)
      n = dec !== undefined ? n + '.' + dec : n
      this.setCurrentChargeMoney = n
    }
  },
  created () {
    this.onCashListSearch()
  },
  data () {
    return {
      type: 'apply',
      setCurrentChargeMoney: '',
      isApplyBonus: false,
      bonus: 0,
      cashList: [],
      allChecked: false,
      cashType: 'in',
      searchDate: {
        startDate: getDateStr(getSubDaysDate(new Date(), 2), 'yyyy-MM-dd 00:00:00'),
        endDate: getDateStr(new Date(), 'yyyy-MM-dd 23:59:59')
      },
      isProcessing: false,
      coinParam: {
        groupid: '',
        groupkey: '',
        groupuserid: '',
        groupusername: '',
        groupstr1: '',
        groupstr2: ''
      }
    }
  },
  methods: {
    checkCoin () {
      if (!window.coinWiki || window.coinWiki.closed) {
        clearInterval(window.coinTimer)
      }
      getCoinWiki(this.coinParam).then(res => {
        const result = res.data
        if (result.resultCode === '0') {
          const coinWikiInfo = result.data.coinWikiInfo
          if (coinWikiInfo) {
            const groupstr2 = coinWikiInfo.groupstr2
            if (groupstr2 === this.coinParam.groupstr2) {
              clearInterval(window.coinTimer)
              window.coinWiki.close()
              this.onAlert('agreeart', '코인입금이 정상처리되었습니다.<br>계속해서 캐시입금을 진행해주세요.<br> 코인입금 신청하신 금액과 동일한 금액을<br>충전신청 해 주시면됩니다.')
            }
          }
        }
      })
    },
    getCoinWikiResult () {
      if (window.coinTimer) {
        clearInterval(window.coinTimer)
      }
      window.coinTimer = setInterval(() => {
        this.checkCoin()
      }, 3000)
    },
    getCoinInfo () {
      window.coinWiki = window.open('', 'coinwikiwindows', 'width=1200,height=900,toolbar=no,directories=no,status=no, scrollbars=yes,resizable=yes,copyhistory=no')

      this.coinParam = {
        groupid: 'stca1',
        groupkey: 'z0NEQAzwd3xXYvd7',
        groupuserid: this.userData.memId,
        groupusername: this.userData.memName,
        groupstr1: process.env.VUE_APP_SITE_ID || 'moka',
        groupstr2: 'https://sta-777.com/coinresult'
      }

      const params = this.coinParam

      console.log('params : ', params)

      document.CoinwikiForm.action = 'https://www.coinwiki.shop/newmatching/'
      document.CoinwikiForm.target = 'coinwikiwindows'
      document.CoinwikiForm.groupid.value = params.groupid // 매장아이디 (필수입력) -
      document.CoinwikiForm.groupkey.value = params.groupkey // 매장 키값 (필수입력) - 16자리
      document.CoinwikiForm.groupuserid.value = params.groupuserid // 매장 회원아이디 (필수입력)
      document.CoinwikiForm.groupusername.value = params.groupusername // 매장 회원이름 (필수입력)
      document.CoinwikiForm.groupstr1.value = params.groupstr1 // 매장에서 사용하는 값 (100자 이 내)
      document.CoinwikiForm.groupstr2.value = params.groupstr2 // 매장에서 사용하는 값2 (100자 이 내)
      document.CoinwikiForm.returntrueurl.value = '' // 비상시 이용 – 정상시 불필요 document.CoinwikiForm.returnfalseurl.value = ""; //비상시 이용 – 정상시 불필요
      document.CoinwikiForm.submit()

      // this.getCoinWikiResult()

      // if (isMobile()) {
      //   window.open('/coinwiki', 'coinwikiwindows')
      // } else {
      //   window.open('/coinwiki', 'coinwikiwindows', 'width=1200,height=900,toolbar=no,directories=no,status=no, scrollbars=yes,resizable=yes,copyhistory=no')
      // }
    },
    async getAcc () {
      const title = '계좌문의입니다.'
      const content = '계좌문의입니다.'
      const params = {
        title: title,
        content: content,
        boardType: 'faq',
        category: '',
        faqType: 'bankaccount'
      }

      await this.setSaveAccountBoard(params, async function () {
        await this.loadList()
      }.bind(this))
    },
    async onRemoveMsg () {
      let checkedCount = 0
      this.cashList.forEach(item => {
        if (item.checked) {
          checkedCount++
        }
      })
      if (checkedCount <= 0) {
        this.onCheck('front.cash.noSelectMessage')
        return false
      }
      const confirm = await this.onConfirm('front.cash.confirmDeleteMessage')

      if (confirm) {
        for (let i = 0, iLen = this.cashList.length; i < iLen; i++) {
          const item = this.cashList[i]
          if (item.checked) {
            cashDelete(item).then(response => {
            })
          }
        }
        this.onCheck('front.cash.completeDeleteMessage')
        this.onCashListSearch()
      }
    },
    setMoney (value) {
      let money = Number(this.setCurrentChargeMoney.replace(/,/g, ''))
      if (value) {
        money += value
      } else {
        money = value
      }
      this.setCurrentChargeMoney = thousand(money)
      // this.setCurrentChargeMoney = thousand(this.setCurrentChargeMoney)
    },
    async onSubmit (device = 'mobile') {
      if (this.setCurrentChargeMoney && this.setCurrentChargeMoney !== '0') {
        const confirm = await this.onConfirm('front.cash.confirmCharge')

        if (confirm) {
          if (!this.isProcessing) {
            this.isProcessing = true
            this.emitter.emit('Loading', true)

            const params = {
              memId: this.userData.memId,
              cashType: 1, // in,
              cashStatus: 'in',
              cashAmt: this.setCurrentChargeMoney.replace(/,/g, '')
            }

            cashIn(params).then(response => {
              store.dispatch('storeUserData').then(userData => {

              }).then(async () => {
                console.log(response)
                const result = response.data

                if (result.resultCode === '0') {
                  this.emitter.emit('Loading', false)
                  const confirm = await this.onCheck('front.cash.completeCharge')
                  if (confirm) {
                    if (device === 'mobile') {
                      this.replacePageByName('my')
                    } else {
                      location.reload()
                    }
                  }
                } else {
                  this.emitter.emit('Loading', false)
                  const confirm = await this.onCheck('api.' + result.resultCode)
                  if (confirm) {
                    if (device === 'mobile') {
                      this.replacePageByName('my')
                    } else {
                      location.reload()
                    }
                  }
                }
              })
            }).catch(err => {
              this.emitter.emit('Loading', false)
              console.error(err)
            })
          }
        }
      } else {
        this.onAlert('warningart', 'front.cash.emptyPrice')
      }
    }
  },
  unmounted () {
    console.log('unmount')
    window.coinTimer = null
    window.coinWiki = null
  }
}
</script>
<style scoped>
.moneyread {padding: 13px 20px;border-radius: 12px;border: 1px solid #2f3545;background-color: rgba(0, 0, 0, 0.25);font-weight: normal;text-align: left;}
.moneyread h3 {color: #fff;font-weight: bold;margin-bottom: 2px;font-size: 16px;}
.moneyread h3 img {height: 27px;vertical-align: middle;margin-right: 15px;}
.moneyread ul li {color: #fff;line-height: 1.5em;font-size: 14px;margin-top: 10px;}
.moneyread ul li img {margin: 0 11px 0 9px;}

.moneyinfoappli {border: 1px solid #2f3545;background-color: rgba(0, 0, 0, 0.25);border-radius: 12px;padding: 25px 51px 27px;margin: 28px 0 75px;}
.moneyinfo {border-radius: 12px;border: 1px dotted #2f3545;background-color: rgba(0, 0, 0, 0.25);padding: 20px;line-height: 1.5em;}
.applih {color: #ffffff;font-size: 16px;font-weight: normal;}
.applip {color: #fff;border-bottom: 1px solid #2f3545;padding: 10px 0 15px;margin-bottom: 15px;font-size: 14px;}
.applibtnWrap {display: flex;gap: 10px;}
.applibtn {width: 109px;height: 34px;border-radius: 5px;background: linear-gradient( to bottom, #f5cc7f, #96653b, #b79453 );font-size: 14px;color: #fff;display: flex;justify-content: center;align-items: center;}
.applibtn.accbtn {padding: 0 5px;}
.applistep {color: #fff;letter-spacing: -1px;font-weight: bold;margin-top: 10px}
.applistep li {counter-increment: li;margin-top: 5px;font-size: 14px;}
.applistep li em {margin-right: 5px;padding: 0px 8px;font-size: 10px;color: #fff;}
.applistep strong {color: #fff;}

.appliinputwrap {padding-top: 48px;}
.appliinputwrap>ul {margin-top: 50px;}
.appliinputwrap>ul:first-child {margin-top: 0;}
.appliinput {display: flex;align-items: center;gap: 36px;font-size: 16px;color: #fff;}
.appliinput li:first-child {width: 122px;font-weight: bold;position: relative;}
.appliinput li:first-child::after {content: '';width: 2px;height: 19px;background: #6b7aa3;position: absolute;right: 0;}
.appliinput li {width: calc(100% - 122px);}
.appliinput li span {display: inline-block;}
.appliselect {border-radius: 5px;border: 1px solid #4575b4;width: 133px;height: 28px;}
.numb {padding: 0;border: 0;background: none;width: 100%; color: #fff;font-size: 16px;}
.moneybtnwrap {display: flex;gap: 5px;margin-top: 26px !important;}
.moneybtnwrap a {display: inline-block;color: #fff;width: 98px;border-radius: 6px;text-align: center;padding: 8px 0;font-weight: bold;}
.moneybtnwrap .one a {background: #817358;}
.moneybtnwrap .two a {background: #bd8a2a ;}
.moneybtnwrap .thr a {background: #96653b;}
.moneybtnwrap .four a {background: #777 ;}
.moneybtnwrap .fiv {padding: 0;width: 130px;height: 34px;display: flex;justify-content: center;align-items: center;font-size: 14px;background: #fff;border: 1px solid #1c594e;color: #1c594e;}
.moneybtnwrap .fiv.on {background: #e1423c;border: 1px solid #4575b4;color: #fff;}
.applibtns {margin-top: 63px;display: flex;justify-content: center;gap: 20px;font-size: 16px;}
.applibtns a {background: linear-gradient( to bottom, #f5cc7f, #96653b, #b79453);border-radius: 10px;width: 193px;height: 48px;color: #fff;font-weight: bold;display: flex;align-items: center;justify-content: center;}
</style>

<style scoped src="@/styles/common.css"></style>
<style scoped src="@/styles/subcommon.css"></style>
